import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@mui/material/CircularProgress'
import { Grid } from '@material-ui/core';
import Page from '../components/Page'
import { Container, Box, Stack,} from '@mui/material'
import { Card, CardHeader } from '@mui/material'
const useStyles = makeStyles(theme => ({
	custom_header: {
		color: "#498dd1",
	}
}))

function FileUploadPage() {
	const [selectedFile, setSelectedFile] = useState();
	const [isFileUploaded, setIsFileUploaded] = useState(false);
	const [fileContent, setFileContent] = useState();
	const [LastUpdated, setlastUpdated] = useState('');
	const [UploadStatus, setUploadStatus] = useState('');
	const classes = useStyles();

	useEffect(() => {
		TemplateMetadata()
	}, [])

	const TemplateMetadata = async () => {
		axios
			.get(process.env.REACT_APP_PQISCG_BACKEND + 's3Metadata',)
			.then((response) => {
				if (response.status === 200) {
					setlastUpdated("[Scorecard Template  was last modified on " + response.data + "UTC]")
					console.log("LastUpdated: ", LastUpdated);
				}
			})
			.catch((error) => {
				console.log("ERROR:", error);
			});
	}

	const readFile = (event) => {
		setSelectedFile(event.target.files[0]);
		let reader = new FileReader();
		reader.readAsDataURL(event.target.files[0])
		reader.onload = (e) => {
			setFileContent(e.target.result);
		}
	};

	const changeHandler = (event) => {
		setIsFileUploaded(true);
		var index = fileContent.indexOf(",") + 1;
		var S = fileContent;
		let body = {
			fileName: "Template_Copy.xlsx",
			fileContent: S.replace(S.substring(0, index), "")
		}
		axios
			.post(process.env.REACT_APP_PQISCG_BACKEND_UPLOADFILE, body)
			.then(response => console.log("result is .. " + response))
		setIsFileUploaded(false);
		setUploadStatus("Successfully updated the new Template");
		TemplateMetadata()
	};


	return (
		<Page title="PQI-SCG">
			<Container>
				<br></br>
				<br></br>
				<Grid item xs={22} md={26} lg={28}>
					<Card>
						<CardHeader titleTypographyProps={{ variant: 'h4' }} className={classes.custom_header} title="Upload a new Scorecard Template" textAlign="center" />
						<Box sx={{ display: 'flex', justifyContent: 'center', boxShadow: 1, width: "100%", }}>
							<div className="card-body">
								<i style={{ color: "#2554C7", fontSize: "15px", paddingLeft: "1px", paddingTop: "30px" }}>{LastUpdated}</i>
								<Stack
									direction="row"
									paddingTop="5px">
									<h4> Select Latest Template [.xlsx file] </h4><input type="file" accept=".xlsx" onChange={readFile} style={{ paddingLeft: "15px" }} />
								</Stack>
								<div style={{ paddingLeft: "145px", paddingTop: "20px" }}>
									<button type="submit" onClick={changeHandler} >Upload File</button>
								</div>
								<Box sx={{ display: 'flex', justifyContent: 'center', boxShadow: 1, width: "100%", }}>
									{isFileUploaded ? (
										<Box sx={{ display: 'flex' }}>
											<CircularProgress />
										</Box>
									) : (
									<p></p>
									)}
								</Box>
								<p style={{ color: "#2554C7", paddingLeft: "40px", paddingTop: "30px" }}>{UploadStatus}</p>
							</div>
							<br></br>
							<br></br>
							<br></br>

						</Box>
					</Card>
				</Grid>
			</Container>
		</Page>
	)
}
export default FileUploadPage