import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
// material
import { styled } from '@mui/material/styles'
import { Box, Button, ButtonGroup, Drawer, Stack } from '@mui/material'
// hooks
import useResponsive from '../../hooks/useResponsive'
// components
import Scrollbar from '../../components/Scrollbar'
import NavSection from '../../components/NavSection'
import AssociateNavSection from "../../components/AssociateNavSection";
//
import navConfig from "./NavConfig";

import associateNavConfig from "./AssociateNavConfig";
import UserAccess from "../../components/Auth/UserAccess";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const accessLevel = UserAccess();
  console.log("Access Level in DashboardSidebar", accessLevel);

  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
        <img src={require("../../icons/pqiscgnew.png")} alt="logo" />
      </Box>

      <NavSection navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{ pt: 5, borderRadius: 2, position: "relative" }}
        >
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: "absolute", top: -50 }}
          />
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
          >
            <Button
              href="https://tiny.amazon.com/wwu4scs0"
              target="_blank"
              variant="contained"
            >
              Report_Bug
            </Button>
            <Button
              href="https://w.amazon.com/bin/view/COPS_BLR_Tools_Automation/"
              target="_blank"
              variant="contained"
            >
              Contact_Us
            </Button>
          </ButtonGroup>
        </Stack>
      </Box>
    </Scrollbar>
  );

  const renderContentAssociate = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
        <img src={require("../../icons/pqiscgnew.png")} alt="logo" />
      </Box>

      <AssociateNavSection associateNavConfig={associateNavConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{ pt: 5, borderRadius: 2, position: "relative" }}
        >
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: "absolute", top: -50 }}
          />

          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
          >
            <Button
              href="https://tiny.amazon.com/wwu4scs0"
              target="_blank"
              variant="contained"
            >
              Report_Bug
            </Button>
            <Button
              href="https://w.amazon.com/bin/view/COPS_BLR_Tools_Automation/"
              target="_blank"
              variant="contained"
            >
              Contact_Us
            </Button>
          </ButtonGroup>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {accessLevel === "Admin" ? renderContent : renderContentAssociate}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {accessLevel === "Admin" ? renderContent : renderContentAssociate}
        </Drawer>
      )}
    </RootStyle>
  );
}