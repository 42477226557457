// routes
import Router from './routes'
import React, { useState, useEffect } from 'react'
// theme
import ThemeProvider from './theme'
// components
import ScrollToTop from './components/ScrollToTop'

import Amplify, { Auth, Hub } from 'aws-amplify'

import UserAccess from "./components/Auth/UserAccess";
import UserId from "./components/Auth/UserId";

// ----------------------------------------------------------------------

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: "us-west-2",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "us-west-2_Unh0mWEKD",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "7il52kgqvq6coqnei5g8bvr60q",

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: "pqi-scg.auth.us-west-2.amazoncognito.com",
      scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
      redirectSignIn: process.env.REACT_APP_REDIRECT_URL,
      redirectSignOut: process.env.REACT_APP_REDIRECT_URL,
      responseType: "code",
    },
  },
});

export default function App() {
  const [user, setUser] = useState(null)

  let [userData, setUserData] = useState(null)

  useEffect(() => {
    getUser().then((userData) => setUserData(userData))
  }, [])

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => console.log('userinfoAppJs', userData))
      .catch(() => Auth.federatedSignIn())
  }
  const accessLevel = UserAccess()

  console.log('Access Level', UserAccess(), UserId())
  console.log('Access.js --UserAccess', UserAccess(), accessLevel)

  return (
    <ThemeProvider>
      <ScrollToTop />
      <Router />
    </ThemeProvider>
  )
}