import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Select, TextField, MenuItem, FormControl, InputLabel } from "@mui/material";
import 'font-awesome/css/font-awesome.css'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress'
import { Container, Box, Stack, } from '@mui/material'
import { Card, CardHeader } from '@mui/material'
import Page from '../components/Page'
import '../css/style.css'
import UserId from '../../src/components/Auth/UserId'
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

var FileSaver = require('file-saver')

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
    margin: theme.spacing(1),
    justifyContent: "space-between",
  },
  rowC: {
    display: "flex",
    justifyContent: "space-between"
  },
  table: {
    minWidth: 300,
  },
  cell_long: {
    minWidth: '10%',
    padding: "8px 12px 8px 8px",
    wordWrap: "break-word",
    width: '1px',
    whiteSpace: 'wrap',
    flexGrow: 1,
    flex: 1,
    maxWidth: "500px",
  },
  cell_short: {
    width: '30%',
    padding: "8px 12px 8px 8px",
    wordWrap: "break-word",
    whiteSpace: 'wrap',
    flexGrow: 1,
    flex: 1,
    inlineSize: "150px",
    maxWidth: "200px",
    overflowWrap: "break-word",
    align: "center",
  },
  custom_header: {
    color: "#498dd1",
    fontSize: "84px",
  },
  custom_italicText: {
    color: "#498dd1",
    fontSize: "14px",
    justifyContent: "right",
    paddingLeft: "49%"
  },
}))

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#498dd1",
    color: theme.palette.common.white,
    fontSize: 18,
    fontWeight: 568,
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const DownloadReport = () => {
  const currentUser = UserId()
  const classes = useStyles();
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [testRunsByMonth, setTestRunsByMonth] = useState("");
  const [segmentsType, setSegmentsType] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [locale, setLocale] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [deviceTypeList, setDeviceTypeList] = useState([]);
  const [localesList, setLocalesList] = useState([]);
  const [segmentsList, setSegmentsList] = useState([]);
  const [yearsList, setYearsList] = useState([]);
  const [valid, setValid] = useState(false);

  const handleDeviceIdSelection = (event) => {
    const reg = new RegExp("[A-Z0-9]{10,15}$");
    setValid(reg.test(event.target.value));
    setDeviceId(event.target.value);
    setDownloadStatusText('');
    console.log(" values for valid are ", valid);
    console.log(" values for deviceId are ", deviceId);
    if (valid === true) {
      setSegmentsList(oldArray => ["Speakers", "Soundbars", "Wearables", "Hearables",
        "Tablets", "Connected Home", "Video & Smart TVs"]);
    }
  }

  const handleSegmentSelection = (event) => {
    setSegmentsType(event.target.value);
    setDeviceTypeList(oldArray => ["Far Field", "PTT"]);
    console.log(" values for deviceTypeList are ", deviceTypeList)
    setDownloadStatusText('')
  }

  const handleDeviceTypeSelection = (event) => {
    setDeviceType(event.target.value);
    setLocalesList(new Array("en-US", "de-DE", "fr-FR", "es-ES", "it-IT", "en-UK", "ar_SA","en_AU"));
    setDownloadStatusText('')
  }
  const handleLocaleSelection = (event) => {
    setLocale(event.target.value);
    setYearsList(oldArray => ["2021", "2022", "2023"]);
    setDownloadStatusText('')
  }

  const handleYearChange = (event) => {
    setYear(event.target.value);
    var filteredMap = new Map();
    {
      Array.from(testRuns.keys()).forEach(testRun => {
        if (testRun.includes(event.target.value)) {
          filteredMap.set(testRun, testRuns.get(testRun))
        }
      })
    }
    testRunsByYear.clear();
    setTestRunsByYear(filteredMap);
    setDownloadStatusText('')
  }

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
    setTestRunsByMonth(testRunsByYear.get(event.target.value));
    setDownloadStatusText('')
  }

  const [tabledata, setTabledata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reportStatus, setReportStatus] = useState(false)
  const [testRuns, setTestRuns] = useState(new Map());
  const [testRunsByYear, setTestRunsByYear] = useState(new Map());
  const [dropdownStatus, setDropdownStatus] = useState('Loading')
  const [downloadStatusText, setDownloadStatusText] = useState('')
  const [downloadStatus, setDownloadStatus] = useState()
  const [startDate, setStartDate] = useState(new Date((new Date()).setDate((new Date()).getDate() - 30)));
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    fetchDropdownLists();
    extractDownloadHistory();
  }, [])

  const fetchDropdownLists = async () => {
    setLoading(true);
    axios
      .get(
        process.env.REACT_APP_PQISCG_BACKEND + 'allTestRuns',
      )
      .then((resp) => {
        var runsByDates = new Map(Object.entries(resp.data));
        const allTestRuns = new Map();
        Array.from(runsByDates.keys()).forEach(testRun => {
          var temp = [];
          runsByDates.get(testRun).forEach(run => {
            temp.push(run['id'] + "|" + run['name']);
          })
          allTestRuns.set(testRun, temp)
        })

        allTestRuns.forEach((value, key) => {
          setTestRuns(new Map(testRuns.set(key, value)));
        });

        setDropdownStatus('Loaded')
        setLoading(false);
      })
      .catch((error) => {
        console.log("ERROR:", error);
        setLoading(false);
      });
  }


  const extractDownloadHistory = () => {
    console.log(startDate + " startDate parameter  " + endDate + "endDate parameter ");
    var startingDate = startDate.toISOString().split('T')[0];
    var endindDate = endDate.toISOString().split('T')[0];
    console.log(" Before api call " + startingDate + "  " + endindDate);

    var url = process.env.REACT_APP_PQISCG_BACKEND + 'reportsHistoryByDate?startDate=' + startingDate + '&endDate=' + endindDate
    setReportStatus(true);
    axios
      .get(url)
      .then((response) => {
        console.log(" length of response " + response.data.length);
        if (response.data.length === 0) {
          setReportStatus(false);
          setTabledata([]);
        }
        else {
          response.data.forEach(function (r) {
            r.resultsLastUpdatedAt = new Date(r.resultsLastUpdatedAt * 1000).toISOString().slice(0, 10).replace(/-/g, "-");;
            var SIZE = r.userIds.length;
            r.reportId = SIZE;
            r.userIds = r.userIds.filter((item, index) => r.userIds.indexOf(item) === index);
            console.log("  size " + r.userIds.size);
            setReportStatus(false);
          });
          setTabledata(response.data);
        }
      })
      .catch((response) => {
        console.error('Failed to extract Reports History.', response)
      })
  }

  const handleNewPQITestReportDownloadRequest = (event) => {
    setDownloadStatus(true);
    setDownloadStatusText('Generating Scorecard..')
    const testRunId = event.target.value.substring(0, event.target.value.indexOf('|'));
    const title = event.target.value.substring(event.target.value.indexOf('|') + 1);
    let responseStatus;
    let requestbody = {
      id: testRunId,
      testRunTitle: title,
      name: currentUser,
      interactionType: deviceType,
      deviceId: deviceId,
      locale: locale,
      segment: segmentsType
    }
    const filename = title + ".xlsx"
    console.log("request body is " + JSON.stringify(requestbody));

    axios
      .post(process.env.REACT_APP_PQISCG_BACKEND_PRECERT_SCORECARD, requestbody)
      .then(async (response) => {
        console.log("response status is " + response.status);
        responseStatus = response.status;
        setDownloadStatus(false)

        if (responseStatus === 200) {
          console.log("Length of Encoded String - ", JSON.stringify(response).length);

          let encodedStringToConvert = response.data //byte[] encoded to plain string from api gateway
          let file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${encodedStringToConvert}`;
          FileSaver.saveAs(file, filename);
          setDownloadStatusText('Report successfully downloaded for ' + title)
        }
        else {
          setDownloadStatusText('Failed to download report for ' + title + '[TestResults are not updated]')
        }
      })
      .catch((response) => {
        console.error(
          'Could not Download the Excel report from the backend.', response)
        setDownloadStatusText('Failed to download report for ' + title)
        setDownloadStatus(false);
      })
    console.log("response status " + responseStatus);
  }

  return (
    <Page title="PQI-SCG">
      <Container>
        <br></br>
        <br></br>
        <Grid item xs={22} md={26} lg={28}>
          <Card>
            <CardHeader titleTypographyProps={{ variant: 'h4' }} title="Select a TestRun to generate the Scorecard " textAlign="center" className={classes.custom_header} />
            <br></br>
            <Box sx={{ display: 'flex', justifyContent: 'center', boxShadow: 1, width: "100%", }}>
              {loading ? (
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Box>
                  <FormControl size="small" className={classes.formControl} >
                    <InputLabel>  </InputLabel>
                    {/* #regex to match the input with uppercase letters and numbers only */}
                    <TextField
                      required
                      id="outlined-required"
                      label="Enter DeviceId(14 Characters max)"
                      variant="standard"
                      deviceId={deviceId}
                      size="medium"
                      onChange={(e) => {
                        handleDeviceIdSelection(e)
                      }}
                      error={!valid}
                      helpertext={deviceId === "" ? 'please enter a value!' : ' '}
                      inputProps={{
                        className: classes.input,
                        pattern: "[A-Z0-9]{14}$",
                        // defaultValue: "DEVICEIDNOTFOUND",
                        // minLength: 15, 
                        maxLength: 16
                      }}
                    />
                  </FormControl>
                  <FormControl size="small" className={classes.formControl} >
                    <InputLabel> Select Segment </InputLabel>
                    <Select onChange={handleSegmentSelection}>
                      {segmentsList.map((item, index) => (
                        <MenuItem value={item}> {item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl size="small" className={classes.formControl} >
                    <InputLabel> Select DeviceType </InputLabel>
                    <Select onChange={handleDeviceTypeSelection}>
                      {deviceTypeList.map((item, index) => (
                        <MenuItem value={item}> {item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl size="small" className={classes.formControl} >
                    <InputLabel> Select Locale </InputLabel>
                    <Select onChange={handleLocaleSelection}>
                      {localesList.map((item, index) => (
                        <MenuItem value={item}> {item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <br></br>
                  <FormControl size="small" className={classes.formControl} >
                    <InputLabel> Select Year </InputLabel>
                    <Select onChange={handleYearChange}>
                      {yearsList.map((item, index) => (
                        <MenuItem value={item}> {item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl size="small" className={classes.formControl} >
                    <InputLabel> Select Month </InputLabel>
                    <Select onChange={handleMonthChange}>
                      {Array.from(testRunsByYear.keys()).map((item, index) => (
                        <MenuItem value={item}> {item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl size="small" className={classes.formControl}>
                    <InputLabel> Select testrun </InputLabel>
                    <Select onChange={handleNewPQITestReportDownloadRequest}>
                      {Array.from(testRunsByMonth).map((item, index) => (
                        <MenuItem value={item}> {item.substring(item.indexOf('|') + 1)}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <p></p>
                  <br></br>
                  <Box sx={{ display: 'flex', justifyContent: 'center', boxShadow: 1, width: "100%", }}>
                    {downloadStatus ? (
                      <Box sx={{ display: 'flex' }}>
                        <br></br>
                        <Stack>
                          <div style={{ color: "#2554C7", paddingLeft: "50px", paddingTop: "10px" }}>
                            <CircularProgress />
                          </div>
                          <div style={{ color: "#2554C7", paddingTop: "10px" }}>
                            <h3>   {downloadStatusText}</h3>
                          </div>
                        </Stack>
                      </Box>
                    ) : (<Box sx={{ display: 'flex' }}>
                      <div style={{ color: "#2554C7", textAlign: "center", paddingTop: "10px" }}>
                        <h3> {downloadStatusText}</h3>
                        <h3> </h3>
                      </div>
                    </Box>)}
                  </Box>
                </Box>)}
            </Box>
          </Card>
        </Grid>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <Grid item xs={12} md={26} lg={28}>
          <Card>
            <Box sx={{ justifyContent: 'center', boxShadow: 1, width: "100%", height: "100%", }}>
              <CardHeader titleTypographyProps={{ variant: 'h4' }} title="Reports History" className={classes.custom_header} />
              <div style={{ paddingLeft: "235px" }}>
                <Stack
                  spacing={0}
                  direction="row"
                  height="10px"
                  marginLeft="10px"
                  justifyContent="flex-end"
                  alignItems=""
                  paddingLeft="40%"
                >

                  <div>
                    <label style={{ color: "#2554C7" }}>StartDate</label>
                    <DatePicker
                      selected={startDate}
                      id="sdate"
                      name="sdate"
                      minDate={new Date(2022, 8, 24)}
                      maxDate={new Date()}
                      startDate={startDate}
                      onChange={date => {
                        setStartDate(date)
                      }}
                    />
                  </div>
                  <div>
                    <label style={{ color: "#2554C7" }}>EndDate</label>
                    <DatePicker
                      selected={endDate}
                      id="edate"
                      name="edate"
                      minDate={startDate}
                      maxDate={new Date()}
                      onChange={date => {
                        setEndDate(date)
                      }
                      }
                    />
                  </div>
                  <div style={{ paddingLeft: "15px", paddingRight: "180px", paddingTop: "24px" }}>
                    <button type="submit" onClick={() => { console.log("cliked"); extractDownloadHistory() }} >Submit</button>
                  </div>

                </Stack>
              </div>
              <br></br>
              <br></br>
              <i className={classes.custom_italicText}>Select a date range to view the details of scorecards generated </i>

              <br></br> <br></br> <br></br>

              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell size='medium'>RunId</StyledTableCell>
                      <StyledTableCell className={classes.cell_long} align="center"> TestRun Title</StyledTableCell>
                      <StyledTableCell align="center">Activation Score</StyledTableCell>
                      <StyledTableCell align="center">Engagement Score</StyledTableCell>
                      <StyledTableCell align="center">Performance Score</StyledTableCell>
                      <StyledTableCell align="center">Overall Score</StyledTableCell>
                      <StyledTableCell align="center" className={classes.cell_short}>Users</StyledTableCell>
                      <StyledTableCell align="center">Report Generated</StyledTableCell>
                      <StyledTableCell align="center">Results LastUpdated</StyledTableCell>

                    </TableRow>
                  </TableHead>

                  {reportStatus ? (
                    <div>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20%', paddingLeft: '650%' }}>
                          <CircularProgress />
                        </div>
                      </Box>
                    </div>
                  ) : (
                    <TableBody>
                      {tabledata
                        .map((item) => {
                          return (
                            <StyledTableRow key={item.resultsLastUpdatedAt + item.runId}>
                              <TableCell size='medium' component="th" scope="row">
                                {item.testRunId}
                              </TableCell>
                              <StyledTableCell className={classes.cell_long} align="left" >
                                {item.productTitle}
                              </StyledTableCell>
                              <StyledTableCell className={classes.cell_long} align="left" >
                                {item.activationScore}
                              </StyledTableCell>
                              <StyledTableCell className={classes.cell_long} align="left" >
                                {item.engagementScore}
                              </StyledTableCell>
                              <StyledTableCell className={classes.cell_long} align="left" >
                                {item.performanceScore}
                              </StyledTableCell>
                              <StyledTableCell className={classes.cell_long} align="left" >
                                {item.overallScore}
                              </StyledTableCell>
                              <StyledTableCell align="center" component="th" scope="row" className={classes.cell_short}>
                                {item.userIds.toString()}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {new Date(item.resultsLastUpdatedAt).toUTCString().substring(0, new Date(item.resultsLastUpdatedAt).toUTCString().indexOf('T'))}
                              </StyledTableCell>
                              <StyledTableCell align="center" component="th" scope="row">
                                {new Date(item.createdAt).toUTCString().substring(0, new Date(item.createdAt).toUTCString().indexOf('T'))}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                    </TableBody>
                  )}

                </Table>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </TableContainer>

            </Box>
          </Card>
        </Grid>

        <br></br>
        <br></br>
        <br></br>
      </Container>
    </Page>
  )
}
export default DownloadReport