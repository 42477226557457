// component
import Iconify from '../../components/Iconify'
import UserAccess from "../../components/Auth/UserAccess";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

let navConfig = [
  {
    title: "downloadReport",
    path: "/dashboard/downloadReport",
    icon: getIcon("eva:cloud-download-fill"),
  },
  {
    title: "uploadTemplate",
    path: "/dashboard/updateTemplate",
    icon: getIcon("eva:cloud-upload-fill"),
  },
  
];

export default navConfig
