import '../../css/style.css'
import { Avatar } from '@mui/material'
import UserId from '../../components/Auth/UserId'
import UserFullName from '../../components/Auth/UserFullName'

import {  makeStyles } from '@material-ui/core/styles';
// ----------------------------------------------------------------------

export default function AccountPopover() {
  const userFullName = UserFullName()
  const userId = UserId()

  return (
    <>
      {
        <>
          <Avatar
            alt=""
            src={
              'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' +
              userId
            }
          ></Avatar>
          <span class="myClass">{userFullName}</span>
        </>
      }
    </>
  )
}
