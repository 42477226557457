// import PropTypes from 'prop-types'
// // material
// import { alpha, styled } from '@mui/material/styles'
// import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material'
// // components
// import Iconify from '../../components/Iconify'
// //
// import Searchbar from './Searchbar'
// import AccountPopover from './AccountPopover'
// import NotificationsPopover from './NotificationsPopover'

// // ----------------------------------------------------------------------

// const DRAWER_WIDTH = 280
// const APPBAR_MOBILE = 64
// const APPBAR_DESKTOP = 92

// const RootStyle = styled(AppBar)(({ theme }) => ({
//  boxShadow: 'none',
//  backdropFilter: 'blur(6px)',
//   WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
//   backgroundColor: alpha(theme.palette.background.default, 0.72),
//   [theme.breakpoints.up('lg')]: {
//     width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
//   },
// }))

// const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
//   minHeight: APPBAR_MOBILE,
//   [theme.breakpoints.up('lg')]: {
//     minHeight: APPBAR_DESKTOP,
//     padding: theme.spacing(0, 5),
//   },
// }))

// // ----------------------------------------------------------------------

// DashboardNavbar.propTypes = {
//   onOpenSidebar: PropTypes.func,
// }

// export default function DashboardNavbar({ onOpenSidebar }) {
//   return (
//       <RootStyle>
   
//       {/* <ToolbarStyle> */}
//         <IconButton
//           onClick={onOpenSidebar}
//           sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}
//         >
//           <Iconify icon="eva:menu-2-fill" />
//         </IconButton>

//         <Box sx={{ flexGrow: 1 }} />

//         <Stack
//           direction="row"
//           alignItems="center"
//           justifyContent="right"
//           spacing={{ xs: 0.5, sm: 1.5 }}
//           paddingRight="6%"
//           paddingTop="2%"
//         >
//           <br></br>
//           <AccountPopover />
//         </Stack>
//       {/* </ToolbarStyle> */}
      
//       </RootStyle>
    
//   )
// }

import PropTypes from 'prop-types'
// material
import { alpha, styled } from '@mui/material/styles'
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material'
// components
import Iconify from '../../components/Iconify'
import AccountPopover from './AccountPopover'

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280
const APPBAR_MOBILE = 64
const APPBAR_DESKTOP = 92

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
    color: "#f9fafb"
  },
}))

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
}

export default function DashboardNavbar({ onOpenSidebar }) {
  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          color="blue"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  )
}